import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConfirmLeaveComponent } from '../components/shared/confirm-leave/confirm-leave.component';
import { AuthenticationService } from './authentication.service';
import { SharedService } from './shared.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(
    public dialog: MatDialog,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService
  ) {}

  canDeactivate(
    component: ComponentCanDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (
      !component.canDeactivate() ||
      !state.url.match(/patient/g) ||
      !route.paramMap.get('id')
    ) {
      if (this.sharedService.getForbiddenError()) {
        this.dialog.closeAll();
        this.sharedService.setForbiddenError(false);
        return true;
      }

      const dialogRef = this.dialog.open(ConfirmLeaveComponent, {
        width: '400px',
        disableClose: true
      });

      return dialogRef.componentInstance.onConfirm.pipe(
        switchMap((isConfirmed: boolean) => {
          if (isConfirmed) {
            return of(true);
          } else {
            const patientId = route.paramMap.get('id');
            return this.authenticationService
              .getPatientAccessToken(patientId)
              .pipe(
                map((tokenResponse) => {
                  if (tokenResponse.ok) {
                    const { token, expiration } = tokenResponse.body;
                    this.authenticationService.setExternalToken(token);
                    this.authenticationService.setExternalTokenExpiration(
                      expiration
                    );
                  }
                  return false;
                }),
                catchError(() => of(false))
              );
          }
        })
      );
    }
    return true;
  }
}
